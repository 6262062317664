<template ref="adminUser">
  <div>
      <header-admin-pages
        title="Тип подраздеоения"
        :counter="$store.getters.getUnitType.length"
      />
      <main>
        <table>
          <tr>
            <td><p>Название</p></td>
            <td><p>Название (Англ)</p></td>
            <td class="drop-icon"></td>
          </tr>
          <tr v-for="item in $store.getters.getUnitType" :key="`country${item.id}`" :class="{ hidden: item.code === 'IMACVARP_IMIHCUBE_XI_S_LABE_TOR_FRPO_IYNABE' }">
            <td><p>{{ item.title }}</p></td>
            <td><p>{{ item.title_eng }}</p></td>
            <td @click="clickItem(item)" class="drop-icon">
              <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                   width="485.219px" height="485.22px" viewBox="0 0 485.219 485.22" style="enable-background:new 0 0 485.219 485.22;"
                   xml:space="preserve">
              <g>
                <path d="M467.476,146.438l-21.445,21.455L317.35,39.23l21.445-21.457c23.689-23.692,62.104-23.692,85.795,0l42.886,42.897
                  C491.133,84.349,491.133,122.748,467.476,146.438z M167.233,403.748c-5.922,5.922-5.922,15.513,0,21.436
                  c5.925,5.955,15.521,5.955,21.443,0L424.59,189.335l-21.469-21.457L167.233,403.748z M60,296.54c-5.925,5.927-5.925,15.514,0,21.44
                  c5.922,5.923,15.518,5.923,21.443,0L317.35,82.113L295.914,60.67L60,296.54z M338.767,103.54L102.881,339.421
                  c-11.845,11.822-11.815,31.041,0,42.886c11.85,11.846,31.038,11.901,42.914-0.032l235.886-235.837L338.767,103.54z
                   M145.734,446.572c-7.253-7.262-10.749-16.465-12.05-25.948c-3.083,0.476-6.188,0.919-9.36,0.919
                  c-16.202,0-31.419-6.333-42.881-17.795c-11.462-11.491-17.77-26.687-17.77-42.887c0-2.954,0.443-5.833,0.859-8.703
                  c-9.803-1.335-18.864-5.629-25.972-12.737c-0.682-0.677-0.917-1.596-1.538-2.338L0,485.216l147.748-36.986
                  C147.097,447.637,146.36,447.193,145.734,446.572z"/>
              </g>
              </svg>
            </td>
          </tr>
        </table>
        <pagination/>
      </main>
  </div>
</template>

<script>
import HeaderAdminPages from '@/components/Admin/HeaderAdminPages'
import Pagination from '@/components/Pagination'
export default {
  name: 'unitType',
  methods: {
    clickItem (value) {
      this.$store.dispatch('setDetailUnitType', value.id)
    }
  },
  components: {
    HeaderAdminPages,
    Pagination
  }
}
</script>

<style scoped lang="scss">
.filters{
  margin-top: 1.37rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-bottom: 1px solid #D5D6D9;
}
.input{
  width: 32%;
  margin-bottom: 1.25rem;
}
.filters-reset {
  background: none;
  font-size: 0.75rem;
  color: #003E78;
  opacity: 0.6;
  margin-right: 1.37rem;
  cursor: pointer;
}
table{
  margin-top: 2rem;
  border-spacing: 0;
  border-collapse: collapse;

  tr{
    padding: 0;
    border-bottom: 1px solid #D5D6D9;
  }
}
td {
  max-width: 23%;
}
tr {
  p {
    font-size: 0.812rem;
    line-height: 154%;
    color: #5E686C;
  }
}
tr {
  a {
    font-size: 0.812rem;
    line-height: 154%;
    color: #246CB7;
  }
}
.drop-icon {
  width: 3rem;
  cursor: pointer;
  svg {
    width: 1rem;
    height: 1rem;
  }
}
.hidden{
  visibility: hidden;
}
</style>
